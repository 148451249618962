import "./banner/Banner.css";
import Banner from "./banner/Banner";
import SecondSection from "./Second-section/SecondSection";
import StepSection from "./Step-section/StepSection";
import MainFeature from "./main-Feature/MainFeature";
import WhyMeetX from "./WhyMeetX/WhyMeetX";
import ExperienceApp from "./Experience-app/ExperienceApp";
import Download_App from "./Download-App/Download_App";

function Home() {
  return (
    <>
      <Banner />
      <SecondSection />
      <StepSection />
      <MainFeature />
      <ExperienceApp />
      <WhyMeetX />
      <Download_App />
    </>
  );
}

export default Home;
