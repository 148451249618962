import Cookies from "js-cookie";
import {
    getData,
    postData,
    putData,
    editStatusData,
    deleteData,
    getDownloadDataExcel,
} from "../../api";

import { Context } from "../../context";
import { useContext } from "react";

import { getProductDetail } from "../Product/Product";

export const loginCart = async () => {
    let cartItems = [];

    const existingCart = Cookies.get('local_mandai_cart');
    if (existingCart) {
        cartItems = JSON.parse(existingCart);
    }
    if (cartItems.length > 0) {
        await Promise.all(cartItems.map(async (value) => {
            await postData(`/cart/add`, {
                product_id: value?.id
            });
        }));
    }
    await Cookies.remove('local_mandai_cart');
}

export const AddToCart = async (id) => {
    try {

        if (Cookies.get('local_mandai_security')) {
            const res = await postData(`/cart/add`, {
                product_id: id
            });
            return true;
        } else {

            let cartItems = [];

            const existingCart = Cookies.get('local_mandai_cart');
            if (existingCart) {
                cartItems = JSON.parse(existingCart);
            }
            const res = await getProductDetail(id);
            if (res?.success) {
                console.log(res?.data, 'no');
                const existingItemIndex = cartItems.findIndex(item => item.id === id);
                if (existingItemIndex === -1) {
                    const data = {
                        id: id,
                        name: res?.data?.name,
                        image: res?.data?.product_images[0]?.image,
                        price: res?.data?.s_price,
                        tax_percent: res?.data?.product_detail?.tax_percentage?.name,
                        quantity: 1,
                    }
                    cartItems.push(data);

                    Cookies.set('local_mandai_cart', JSON.stringify(cartItems));
                }
            }
        }

        return true;

    } catch (error) {
        console.log(error);
    }
};

export const CartQuantity = async (id, type) => {
    try {

        if (Cookies.get('local_mandai_security')) {
            const res = await postData(`/cart/quantity`, {
                cart_id: id,
                type: type,
            });
            return true;
        } else {

            let cartItems = [];

            const existingCart = Cookies.get('local_mandai_cart');
            if (existingCart) {
                cartItems = JSON.parse(existingCart);
                Cookies.remove('local_mandai_cart');
            }

            const existingItemIndex = cartItems.findIndex(item => item.id === id);
            if (existingItemIndex === -1) {

            } else {

                if (cartItems[existingItemIndex]?.quantity === 1 && type === 'minus') {
                    cartItems.splice(existingItemIndex, 1);
                } else {
                    if (type === 'minus') {
                        cartItems[existingItemIndex].quantity -= 1;
                    } else {
                        cartItems[existingItemIndex].quantity += 1;
                    }
                }
                Cookies.set('local_mandai_cart', JSON.stringify(cartItems));
            }
        }

        return true;

    } catch (error) {
        console.log(error);
    }
};


export const getCart = async () => {
    try {
        return await getData(`/cart/show`);
    } catch (error) {
        console.log(error);
    }
};

export const getCartCheck = async (id) => {
    try {
        if (Cookies.get('local_mandai_security')) {
            return await getData(`/cart/getCart/${id}`);
        } else {
            let cartItems = [];

            const existingCart = Cookies.get('local_mandai_cart');
            if (existingCart) {
                cartItems = JSON.parse(existingCart);
            }

            const existingItemIndex = cartItems.findIndex(item => item.id == id);
          
            if (existingItemIndex === -1) {

            } else {
                return {success:true ,cartItem : cartItems[existingItemIndex]};
            }
        }


    } catch (error) {
        console.log(error);
    }
};

