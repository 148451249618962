import React from "react";
import MeetBanner from "./MeetBanner/MeetBanner";
import MeetVisinories from "./MeetVisinories/MeetVisinories";

const MeetFounders = () => {
    return (
        <>
          <MeetBanner/>
          <MeetVisinories/>
        </>
    );
};

export default MeetFounders;
