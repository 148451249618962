import React from "react";
import "./AboutBanner.css";
const AboutBanner = () => {
    return (
        <>
            <section className="About-Banner">
                <div className="About-img">
                    <img
                        className="Banner"
                        src={process.env.PUBLIC_URL + "/assest/image/About/about Us banner.png"}
                        alt="vision-man"
                    />
                    <div className="banner-content">
                        <h1 className="main-heading">About Us</h1>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AboutBanner;
