import React from "react";
import { Container, Row } from "react-bootstrap";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
    return (
        <>
            <section className="PrivacyPolicy">
                <Container>
                    <Row>
                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6">
                        <h1 className="heading">Privacy Policy</h1>
                    </div>
                    <div className="col-xxl-9 col-xl-9 col-lg-8 col-md-6">
                        <div className="border-heading mt-4">
                        </div>
                    </div>
                    <h4 className="sub-heading mt-3">Our Commitment To You</h4>
                    <p className="sub-text mt-4">
                        At MeetX, safeguarding your privacy stands as our foremost commitment. It's ingrained in every
                        aspect of our service & product development, ensuring you can place complete trust in them and
                        concentrate on nurturing genuine connections.
                    </p>
                    <p className="sub-text mt-4">
                        Your trust means the world to us, & we don't take it for granted when you share your information
                        with us. It's a responsibility we deeply respect & value.
                    </p>
                    <div className="content">
                        <div className="Point mt-4">
                            <p className="sub-text">Our commitment to privacy: </p>
                            <p className="text">
                                Ensuring your privacy is our top priority in every product & service we offer. Our
                                approach involves collaborating with experts across diverse fields such as legal,
                                security, engineering, and product design. This ensures that every decision we make is
                                grounded in the highest standards of privacy protection, reflecting our unwavering
                                commitment to your trust and confidentiality.
                            </p>
                        </div>
                        <div className="Point mt-4">
                            <p className="sub-text">Our commitment to privacy: </p>
                            <p className="text">
                                Ensuring your privacy is our top priority in every product & service we offer. Our
                                approach involves collaborating with experts across diverse fields such as legal,
                                security, engineering, and product design. This ensures that every decision we make is
                                grounded in the highest standards of privacy protection, reflecting our unwavering
                                commitment to your trust and confidentiality.
                            </p>
                        </div>
                        <div className="Point mt-4">
                            <p className="sub-text">Our commitment to privacy: </p>
                            <p className="text">
                                Ensuring your privacy is our top priority in every product & service we offer. Our
                                approach involves collaborating with experts across diverse fields such as legal,
                                security, engineering, and product design. This ensures that every decision we make is
                                grounded in the highest standards of privacy protection, reflecting our unwavering
                                commitment to your trust and confidentiality.
                            </p>
                        </div>
                        <p className="text">
                            At MeetX, we are committed to protecting your privacy and ensuring the security of your
                            personal information. This Privacy Policy outlines how we collect, use, and safeguard your
                            data when you use our dating app.
                        </p>
                        <div className="Point mt-4">
                            <p className="sub-text">Information We Collect: </p>
                            <p className="text">
                                Personal Information: When you create an account, we collect information such as your
                                name, email address, date of birth, gender, and location. Profile Information: You have
                                the option to provide additional information about yourself, including photos,
                                interests, and preferences. Communication Data: We may collect data from your
                                interactions with other users, such as messages and chat history.
                            </p>
                        </div>

                        <div className="Point mt-4">
                            <p className="sub-text">How We Use Your Information:</p>
                            <p className="text">
                                Personalization: We use your information to personalize your experience on the app,
                                including suggesting potential matches based on your preferences. Communication: Your
                                data enables us to facilitate communication between you and other users.
                            </p>
                        </div>

                        <div className="Point mt-4">
                            <p className="sub-text">Service Improvement: </p>
                            <p className="text">
                                We may analyze usage patterns to improve our app's functionality and user experience.
                            </p>
                        </div>

                        <div className="Point mt-4">
                            <p className="sub-text">Data Security:</p>
                            <p className="text">
                                We employ industry-standard security measures to protect your personal information from
                                unauthorized access, disclosure, or alteration. Your account is protected by a password,
                                and we recommend choosing a strong, unique password and keeping it confidential.
                            </p>
                        </div>

                        <div className="Point mt-4">
                            <p className="sub-text">Data Sharing:</p>
                            <p className="text">
                                We do not sell, rent, or share your personal information with third parties for
                                marketing purposes without your consent. However, we may share data with trusted service
                                providers who assist us in operating our app, such as cloud hosting providers and
                                analytics services.
                            </p>
                        </div>

                        <div className="Point mt-4">
                            <p className="sub-text">Consent and Control:</p>
                            <p className="text">
                                By using our app, you consent to the collection, processing, and storage of your
                                information as described in this Privacy Policy. You have the right to access, update,
                                or delete your personal information at any time through the app's settings.
                            </p>
                        </div>

                        <div className="Point mt-4">
                            <p className="sub-text">Children's Privacy:</p>
                            <p className="text">
                                Our app is not intended for use by individuals under the age of 18. We do not knowingly
                                collect personal information from minors.
                            </p>
                        </div>

                        <div className="Point mt-4">
                            <p className="sub-text">Changes to this Policy:</p>
                            <p className="text">
                                We reserve the right to update or revise this Privacy Policy from time to time. We will
                                notify you of any material changes through the app or via email. By using MeetX, you
                                agree to the terms outlined in this Privacy Policy. If you have any questions or
                                concerns about our privacy practices, please contact us at support@meetx.in
                            </p>
                        </div>
                    </div>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default PrivacyPolicy;