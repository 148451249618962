import React from "react";
import "./MeetVisinories.css";
import {Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook} from "@fortawesome/free-brands-svg-icons";
import {faLinkedin} from "@fortawesome/free-brands-svg-icons";
import {faTwitter} from "@fortawesome/free-brands-svg-icons";
import {text} from "@fortawesome/fontawesome-svg-core";
const MeetVisinories = () => {
    const VisinoriesCards = [
        {
            images: process.env.PUBLIC_URL + "/assest/image/Founder/man1.png",
            subtext: "Andrew Max Fetcher",
            position: "CEO",
        },
        {
            images: process.env.PUBLIC_URL + "/assest/image/Founder/man2.png",
            subtext: "Mike Holder",
            position: "COO",
        },
        {
            images: process.env.PUBLIC_URL + "/assest/image/Founder/man1.png",
            subtext: "Andrew Max Fetcher",
            position: "CEO",
        },
    ];
    return (
        <>
            <section className="Meet-visionaries">
                <Container>
                    <h1 className="heading mb-4">Meet the visionaries behind</h1>
                    <Row>
                        {/* {VisinoriesCards.map((item, index) => (
                            <Col xxl={4} xl={4} lg={4} md={6} key={index}>
                                <div className="main-vision">
                                    <div className="vision-imag">
                                        <img className="vision-man" src={item.images} alt="vision-man" />
                                    </div>
                                    <div className="overlay-one"></div>
                                    <div className="content-card">
                                        <div className="textssss">
                                            <p className="sub-text mb-0">Andrew Max Fetcher</p>
                                            <p className="sub-text mb-0">CEO</p>
                                            <div className="overlay"></div>
                                        </div>

                                        <div className="social">
                                            <div className="social-icons">
                                                <div className="circle">
                                                    <FontAwesomeIcon icon={faFacebook} />
                                                </div>
                                                <div className="circle">
                                                    <FontAwesomeIcon icon={faLinkedin} />
                                                </div>
                                                <div className="circle">
                                                    <FontAwesomeIcon icon={faTwitter} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        ))} */}
                    </Row>
                    <div className="Content-vision mt-4">
                        <p className="sub-heading">The visionaries behind our journey – </p>
                        <p className="text">
                            our founders. With a blend of passion, expertise, and unwavering determination, they breathe
                            life into our mission every day. Each bringing unique perspectives & skills, they form the
                            cornerstone of our company's success. Get to know the faces behind the innovation driving
                            our growth. Together, they inspire, lead, and shape the future of our organization."
                        </p>
                        <p className="text">
                            "Embark on a journey to meet the driving forces behind our venture – our esteemed founders.
                            With diverse backgrounds and shared aspirations, they converged to forge our path to
                            success. Individually, they bring a wealth of experience, expertise, and relentless
                            dedication to the table. From the inception of our vision to navigating challenges, their
                            leadership has been instrumental. Their unwavering commitment to excellence fuels our
                            collective ambition & propels us forward. Through their guidance, we've cultivated a culture
                            of innovation and collaboration, shaping our identity. Meet the faces behind our story, each
                            with a unique story and a common goal. Join us in celebrating their vision, determination, &
                            the impact they've made. Together, we strive to exceed expectations, driven by the spirit
                            they instill in us. Get acquainted with the remarkable individuals who laid the foundation
                            for our journey. Welcome to the world of our founders – the architects of our success."
                        </p>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default MeetVisinories;
