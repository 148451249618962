import React, { useEffect } from "react";
import { Container, Row } from 'react-bootstrap'
import './Imagesec.css'
import AOS from 'aos';
import 'aos/dist/aos.css';

const ImageSec = () => {

    useEffect(() => {
        AOS.init();
    }, [])
    
    return (
        <>
            <section className='image-sec'>
                <Container>
                    <Row>
                        <div className='Phone-img text-center'>
                            <img data-aos="zoom-in"  data-aos-duration="2000" src={process.env.PUBLIC_URL + "/assest/image/homepage/A-Iphone.png"} className="iphone-img" />
                        </div>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default ImageSec