import React from 'react'
import { Container, Row } from 'react-bootstrap'
import './TermsAndCondition.css'
const ShippingPolicy = () => {
    return (
        <>
            <section className='Shipping-policy'>
                <Container>
                    <Row>
                        <div className='heading-holder text-center mb-3'>
                            <h1>Shipping and Delivery Policy</h1>
                        </div>
                        <div className='text-holder'>
                            <ul>
                                <li>Altrodav Technologies Private Limited is an online only platform with no
                                    storefront and does not offer any physical shipping or delivery services.
                                    Therefore there are no charges associated with shipping and delivery since
                                    the availability of the above said services itself is non-existent in the first
                                    place.
                                </li>


                            </ul>
                        </div>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default ShippingPolicy
