import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faSquareInstagram } from "@fortawesome/free-brands-svg-icons";
const Footer = () => {
  return (
    <>
      <section className="footer">
        <div className="x-image text-end">
          <img className="x-image-foot" src={
            process.env.PUBLIC_URL + "/assest/image/icon/Scroll-1.png"} alt="Logo" />
        </div>
        <Container>

          <Row>
            <Col lg={2} md={12} >
              <Link as={Link} to="/">
                <img className="headlogo" src={
                  process.env.PUBLIC_URL + "/assest/image/Logo/Logo-final.png"} alt="Logo" />
              </Link>
            </Col>
            <Col lg={3} md={12}>
              <div className="headimg-holder">
                <h2>Quick Links</h2>
              </div>
              <div className="link-heading">
                <Link to='/about-us'>  <p>About Us</p></Link>
                <Link to='/meet-founder'>  <p>Meet the Founders</p></Link>
                <Link to='/privacy-policy'><p>Privacy Policy</p></Link>
                <Link to='/terms-condition'>  <p>Terms & Conditions</p></Link>
                <Link to='/cancelation-policy'>  <p>Cancellation & Refund Policy</p></Link>
                <Link to='/shipping-policy'>  <p>Shipping & Delivery Policy</p></Link>
              </div>
            </Col>
            <Col lg={5} md={12}>
              <div className="headimg-holder">
                <h2>Contact Us</h2>
              </div>
              <div className="d-flex link-heading">
                <div className="white-circle me-2">
                  <img className="locate-icon" src={process.env.PUBLIC_URL + "/assest/image/icon/locate.png"} alt="icon" />
                  {/* <FontAwesomeIcon icon={faLocationDot} className="locate-icon" /> */}
                </div>
                <div className="address-holder">
                  <p>Kaverappa Layout, Kadubeesanahalli, Kadabeesanahalli, Bengaluru, Karnataka 560103</p>
                </div>
              </div>
              <div className="d-flex ">
                <div className="white-circle me-2">
                  <img className="locate-icon" src={process.env.PUBLIC_URL + "/assest/image/icon/calling.png"} alt="icon" />
                  {/* <FontAwesomeIcon icon={faPhone}  className="locate-icon" /> */}
                </div>
                <div className="address-holder">
                  <p>+91 70226 65938</p>
                </div>
              </div>
              <div className="d-flex ">
                <div className="white-circle me-2">
                  <img className="locate-icon" src={process.env.PUBLIC_URL + "/assest/image/icon/email-icon.png"} alt="icon" />
                  {/* <FontAwesomeIcon icon={faEnvelope} className="locate-icon" /> */}
                </div>
                <div className="address-holder">
                  <p>manimonu@meetx.co.in</p>
                </div>
              </div>
            </Col>
            <Col lg={2} md={12}>
              <div className="headimg-holder">
                <h2>Follow Us</h2>
              </div>
              <div className="social-icon link-heading">
                <Link to="https://www.facebook.com/people/MeetX/61559032034896/?mibextid=JRoKGi" target="___blank">
                  <img className="icon-logo" src={process.env.PUBLIC_URL + "/assest/image/icon/facebook.png"} alt="icon" />
                </Link>

                <img className="icon-logo" src={process.env.PUBLIC_URL + "/assest/image/icon/linkdin.png"} alt="icon" />

                <img className="icon-logo" src={process.env.PUBLIC_URL + "/assest/image/icon/twiteer.png"} alt="icon" />

                <Link to="https://www.youtube.com/channel/UCeA0l5QqQZ5RNMROWkTqmIA" target="___blank">
                  <img className="icon-logo" src={process.env.PUBLIC_URL + "/assest/image/icon/youtube.png"} alt="icon" />
                </Link>

                <Link to="https://www.instagram.com/meetx_24/" target="___blank" >
                  {/* <div className=""> */}
                  <FontAwesomeIcon icon={faInstagram} className="insta-icon" />
                  {/* </div> */}
                </Link>

              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Footer;
