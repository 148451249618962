import React from 'react'
import ImageSec from './ImageSec/ImageSec'
import SliderInterFace from './slider-interface/SliderInterFace'


const SecondSection = () => {
    return (
        <>
            <ImageSec />
            <SliderInterFace />
           
        </>
    )
}

export default SecondSection