import React, { useState, useEffect, useRef } from "react";
import "./Header.css";
import { Link, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import CommanButton from "../CommanComponent/Comman_button/CommanButton";

function Header() {
  const location = useLocation();
  const [activeItem, setActiveItem] = useState(() => {
    return localStorage.getItem("activeItem") || "home";
  });

  useEffect(() => {
    const path = location.pathname.substr(1);
    updateActiveItem(path || "home");
  }, [location]);

  const updateActiveItem = (newActiveItem) => {
    localStorage.setItem("activeItem", newActiveItem);
    setActiveItem(newActiveItem);
  };
  const [activeLink, setActiveLink] = useState("home");
  useEffect(() => {
    const path = location.pathname.substring(1);
    setActiveLink(path || "home");
  }, [location.pathname]);
  const handleNavItemClick = (selectedLink) => {
    setActiveLink(selectedLink);
    const navbarToggle = document.querySelector(".navbar-toggler");
    if (navbarToggle && window.innerWidth <= 992) {
      navbarToggle.click();
    }
  };

  return (
    <>
      {/* start header section  */}
      <section className="header-sec">
        <Container>
          <Navbar expand="lg">
            <Navbar.Brand as={Link} to="/">
              <img
                className="headlogo"
                src={
                  process.env.PUBLIC_URL + "/assest/image/Logo/Logo-final.png"
                }
                alt="Logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mx-auto">
                <Nav.Link
                  as={Link}
                  to="/"
                  active={activeItem === "home"}
                  onClick={handleNavItemClick}
                >
                  <img
                    className="x-image me-1"
                    src={
                      process.env.PUBLIC_URL + "/assest/image/Logo/Logo-x.png"
                    }
                    alt="x-image"
                  />
                  Home

                  <div className="border-bottom"></div>
                </Nav.Link>

                <Nav.Link
                  as={Link}
                  to="/about-us"
                  active={activeItem === "about-us"}
                  onClick={handleNavItemClick}
                >
                  <img
                    className="x-image  me-1"
                    src={
                      process.env.PUBLIC_URL + "/assest/image/Logo/Logo-x.png"
                    }
                    alt="x-image"
                  />{" "}
                  About Us
                  <div className="border-bottom"></div>
                </Nav.Link>

                {/* <Nav.Link
                  as={Link}
                  to="/meet-founder"
                  active={activeItem === "meet-founder"}
                  onClick={handleNavItemClick}
                >
                  <img
                    className="x-image  me-1"
                    src={
                      process.env.PUBLIC_URL + "/assest/image/Logo/Logo-x.png"
                    }
                    alt="x-image"
                  />{" "}
                  Meet the Founders
                  <div className="border-bottom"></div>
                </Nav.Link> */}
              </Nav>
              <div className="contact-button">
                <Link to="/contact-us"  as={Link} active={activeItem === "contact-us"}
                  onClick={handleNavItemClick}>
                  <CommanButton className={"contact-btn"} text={"Contact Us"} />
                </Link>
              </div>
            </Navbar.Collapse>
          </Navbar>
        </Container>
      </section>
      {/* end header section  */}
    </>
  );
}

export default Header;
