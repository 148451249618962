import React from 'react'
import LazyLoad from 'react-lazyload'
import {Col, Container, Row} from "react-bootstrap";
import "./MeetBanner.css";
const MeetBanner = () => {
  return (
    <>
         <section className="Meet-Founders">
                <Container>
                    <Row className="main">
                        <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12} className='p-0'>
                            <div className="Video">
                            <LazyLoad>
                                        <video className='video' controls  autoPlay loop mute >
                                            <source src={process.env.PUBLIC_URL + '/assest/image/Founder/meet.mp4'} className='video'></source>
                                        </video>

                                    </LazyLoad>
                            </div>
                        </Col>
                        <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12} className='p-0'>
                            <div className="text-background">
                                <div className="content">
                                    <h1 className="big-heading">Driven by dedication,<br></br> united by purpose.</h1>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
    </>
  )
}

export default MeetBanner
