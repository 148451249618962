import React from 'react'
import { Container, Row } from 'react-bootstrap'
import './TermsAndCondition.css'
const CancelationRefundPolicy = () => {
    return (
        <>
            <section className='cancelation-policy'>
                <Container>
                    <Row>
                        <div className='heading-holder text-center mb-3'>
                            <h1>Cancellation and Refund Policy</h1>
                        </div>
                        <div className='text-holder'>
                            <ul>
                                <li>Refund Eligibility: If a user has paid for a meeting, and the other
                                    party fails to attend, a refund will be processed within 7 working
                                    days.
                                </li>
                                <li>Non-Refundable Scenarios: If the user who booked the meeting fails
                                    to attend, no refund will be issued.</li>
                                <li>Refund Processing: Eligible refunds will be processed and credited
                                    back to the original payment method within 7 working days of the
                                    request.</li>
                                <li>Policy Changes: Altrodav Technologies Private Limited reserves the
                                    right to modify this Cancellation and Refund Policy at any time
                                    without prior notice.</li>

                            </ul>
                        </div>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default CancelationRefundPolicy
