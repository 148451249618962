import React from 'react'
import './SliderInterFace.css'
import { Col, Container, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical, faHandsHoldingChild, faHeart, faMagnet, faSliders, faUserCheck, faUserClock } from '@fortawesome/free-solid-svg-icons';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/swiper-bundle.css";
import "swiper/css";
import icon1 from '../icons1/1.svg'
import icon2 from '../icons1/2.svg'
import icon3 from '../icons1/3.svg'
import icon4 from '../icons1/4.svg'

const SliderInterFace = () => {
    const cardData = [
        {
            title: 'Skill-Based Connections',
            description: 'Connect with professionals who possess the exact skills you need. MeetX facilitates meaningful interactions based on expertise, ensuring you gain valuable insights and knowledge.',
            icon: icon1,
        },
        {
            title: 'Flexible Payment Options',
            description: 'Choose how you want to connect: pay for expert advice or exchange knowledge for free. Experience hassle-free payments and enjoy the benefits of a transparent platform.',
            icon: icon2,
        },
        {
            title: 'Zero-Fake Profile',
            description: 'Our advanced facial recognition system ensures a safe and trustworthy platform for professionals by eliminating duplicates guarantying  genuine connections.',
            icon: icon3,
        },
        {
            title: 'Location-Based Networking',
            description: 'Discover professionals near you. Our real-time location-based feature lets you connect based on current activities, fostering spontaneous collaborations.',
            icon: icon4,
        },
    ];
    return (
        <>
            <section className='slider-interface'>
                <Container>
                    <Row>
                        <Swiper
                            modules={[Pagination]}
                            onSlideChange={() => console.log("slide change")}
                            onSwiper={(swiper) => console.log(swiper)}
                            pagination={{
                                dynamicBullets: true,
                            }}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                360: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                425: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                467: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                567: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                640: {
                                    slidesPerView: 2,
                                    spaceBetween: 30,
                                },

                                768: {
                                    slidesPerView: 3,
                                    spaceBetween: 20,
                                },
                                992: {
                                    slidesPerView: 3,
                                    spaceBetween: 10,
                                },
                                1024: {
                                    slidesPerView: 4,
                                    spaceBetween: 10,
                                },
                                1440: {
                                    slidesPerView: 4,
                                    spaceBetween: 10,
                                },
                            }}
                        >
                            {cardData.map((card, index, item) => (
                                <SwiperSlide key={index}>
                                    <div className={index < 3 ?`interface-card` : `interface-card2`}>
                                        <div className='heading-holder'>
                                            <img src={process.env.PUBLIC_URL + card?.icon} alt='icons' className='fa-icon' />
                                            <h2 className='text'>{card.title}</h2>
                                        </div>
                                        <div className='text-holder'>
                                            <p className='text'>{card.description}</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>

                    </Row>
                </Container>
            </section>
        </>
    )
}

export default SliderInterFace