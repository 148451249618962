import React, { useEffect } from "react";
import "./Banner.css";
import axios from "axios";
import { Col, Row } from "react-bootstrap";
import { Typewriter } from 'react-simple-typewriter'

import AOS from 'aos';
import 'aos/dist/aos.css';


function Banner() {

  useEffect(() => {
    AOS.init();
  }, [])

  AOS.init({ disable: 'mobile' });


  return (
    <section className="home-banner">
      <div className="image-holder">
        <img src={process.env.PUBLIC_URL + "/assest/image/homepage/home-ban2.png"} className="bann-img" />
        <div className="image-overlay"></div>
      </div>
      {/* <div className="container"> */}
      <Row>
        <Col lg={6} md={12} sm={12}>
          <div className="main-banner-sec">
            <div className="text-holder">
              <h1 className="main-heading">Unlock a World <br/> of New Possibilities!</h1>
            </div>
            <div className="border-line my-4"></div>
            <div className="text-holder mb-3">
              <h2 className="main-heading">Explore the World <br/> in a whole new way!</h2>
            </div>
            <div className="download-btn">
              <p>Download the App</p>
              <button className="btn-down"><img src={process.env.PUBLIC_URL + "/assest/image/homepage/google-play.png"} className="google-img me-2" /></button>
              <button className="btn-down"><img src={process.env.PUBLIC_URL + "/assest/image/homepage/aap-store.png"} className="google-img" /></button>
            </div>
          </div>
        </Col>
      </Row>

      {/* </div> */}
    </section>
  );
}

export default Banner;