import React, { useState } from "react";
import "./Download_App.css";

function Download_App() {
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredRight, setIsHoveredRight] = useState(false);
  const [isHoveredLeft, setIsHoveredLeft] = useState(false);
  const [isHoveredImage, setIsHoveredImage] = useState(false);
  const [isHoveredBg, setIsHoveredBg] = useState(false);
  const [isHoveredUpTl, setIsHoveredUpTl] = useState(false);

  return (
    <>
      <div className="Download_App">
        <div className="container">
          <div
            className={`Download_App_content_holder ${
              isHovered ? "scale-up-center" : "scale-down-center"
            }`}
          >
            <div className="row">
              <div className="col-md-6">
                <div className="text_content">
                  <div>
                    <div className="main_heading_holder">
                      {/* <h1 className="firstText">START EXPLORING <br/> IN JUST A TAP</h1> */}
                      {/* <h1
                        className={`firstText ${
                          isHovered ? " slide-left" : "slide-left-Reverse"
                        }`}
                        onMouseEnter={() => setIsHoveredLeft(true)}
                        onMouseLeave={() => setIsHoveredLeft(false)}
                      >
                        START EXPLORING <br /> IN JUST A TAP
                      </h1>  */}
                      <h1
                        className="firstText slide-left"
                      >
                        START EXPLORING <br /> IN JUST A TAP
                      </h1>
                      {/* <h1 className="secondText">START EXPLORING <br/> IN JUST A TAP</h1> */}
                      <h1
                        className={`secondText ${
                          isHovered ? "slide-right " : "slide-right-reverse "
                        }`}
                        
                      >
                        START EXPLORING <br /> IN JUST A TAP
                      </h1>
                    </div>

                    <div className="subheading_holder">
                      <h2>Download the App</h2>
                    </div>

                    <div className="get_app_holder">
                      <div
                        className={`img-holder ${
                          isHovered ? "scale-up-tl" :  "scale-down-tl" 
                        }`}
                        
                      >
                        <img
                          className="app_img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assest/image/homepage/DownloadApp/app_1.png"
                          }
                          alt="app_img"
                        />
                      </div>

                      <div  className={`img-holder ${
                          isHovered ? "scale-up-tl" :  "scale-down-tl" 
                        }`}
                       >
                        <img
                          className="app_img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assest/image/homepage/DownloadApp/app_2.png"
                          }
                          alt="app_img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="main-img-holder">
                  <div
                    className={`bg-img-holder ${
                      isHovered ? "rotate-center-bg" : "rotate-center-bg-Reverse"
                    }`}
                    
                  >
                    <img
                      className="mobile_bg_img"
                      src={
                        process.env.PUBLIC_URL +
                        "/assest/image/homepage/DownloadApp/bg-img.png"
                      }
                      alt="mobile_bg_img"
                    />
                  </div>

                  <div className="overlay-img-holder">
                    <div
                      className={`img-holder single_mobile ${
                        isHovered ? " rotate-center " : "rotate-center-Reverse"
                      }`}
                     
                    >
                      <img
                        className="mobile_img"
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/image/homepage/DownloadApp/mobiles_3.png"
                        }
                        alt="mobile_img"
                      />
                    </div>

                    <div
                      className={`img-holder grp_mobile ${
                        isHovered ? " rotate-center " : "rotate-center-Reverse"
                      }`}
                      
                    >
                      <img
                        className="mobile_img"
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/image/homepage/DownloadApp/mobiles_3.png"
                        }
                        alt="mobile_img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Download_App;
