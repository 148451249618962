import React, { useState } from 'react'
import './ExperienceApp.css'
import { Container, Row } from 'react-bootstrap'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { EffectCoverflow, Autoplay } from 'swiper/modules';
const ExperienceApp = () => {
    const qualifiedData = [
        {

            iconSrc: process.env.PUBLIC_URL + "/assest/image/homepage/6aMeetX.png",

        },
        {

            iconSrc: process.env.PUBLIC_URL + "/assest/image/homepage/Screen_JohnDoe.png",

        },
        {

            iconSrc: process.env.PUBLIC_URL + "/assest/image/homepage/Screen_Lauren.png",

        },
        {

            iconSrc: process.env.PUBLIC_URL + "/assest/image/homepage/FollowerProfile.png",

        },
        {

            iconSrc: process.env.PUBLIC_URL + "/assest/image/homepage/Profile.png",

        },
        {

            iconSrc: process.env.PUBLIC_URL + "/assest/image/homepage/Post_Multiple.png",

        },
        {

            iconSrc: process.env.PUBLIC_URL + "/assest/image/homepage/Profile.png",

        },
        {

            iconSrc: process.env.PUBLIC_URL + "/assest/image/homepage/edit-profile.png",

        },
        {

            iconSrc: process.env.PUBLIC_URL + "/assest/image/homepage/Screen_Lauren.png",

        },
        {

            iconSrc: process.env.PUBLIC_URL + "/assest/image/homepage/LaurenRose.png",

        },

    ];
    const [swiperInstance, setSwiperInstance] = useState(null);
    const handleMainSliderClick = (direction) => {
        if (swiperInstance) {
            if (direction === "prev") {
                swiperInstance.slidePrev();
            } else if (direction === "next") {
                swiperInstance.slideNext();
            }
        }

    };
    return (
        <>
            <section className='experience-app'>
                <Container>
                    <Row>
                        <div className='heading-holder text-center'>
                            <h2 className='heading'>Experience the App</h2>
                        </div>

                        <Swiper

                            effect={'coverflow'}
                            grabCursor={true}
                            centeredSlides={true}
                            loop={true}
                            slidesPerView={'auto'}
                            spaceBetween={30}
                            coverflowEffect={{
                                rotate: 0,
                                stretch: 0,
                                depth: 120,
                                modifier: 1,
                                slideShadows: false,
                            }}

                            modules={[EffectCoverflow, Autoplay]}

                            className="mySwiper"
                            onSwiper={(swiper) => setSwiperInstance(swiper)}

                        >
                            {qualifiedData.map((qualification, index) => (
                                <SwiperSlide>
                                    <div className=' mb-5'>
                                        <div className='orange-circle'>
                                            <img className='bg-img' src={qualification.iconSrc} alt={`icon-${index}`} />
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                            <div className='mobile-screen'>
                                <img className='mobile-img' src={process.env.PUBLIC_URL + "/assest/image/homepage/mobile-cover2.png"} alt={'mobile-img'} />
                            </div>
                        </Swiper>

                        <div
                            className="main-slider "
                            onClick={() => handleMainSliderClick("prev")}
                        >
                            <div className='main-arrow-back'>
                                <div className="arrow-back arrowmobile" />
                            </div>
                        </div>
                        <div
                            className="main-slider"
                            onClick={() => handleMainSliderClick("next")}
                        >
                            <div className='main-arrow-next'>
                                <div className="arrow-next arrowmobile" />
                            </div>
                        </div>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default ExperienceApp