import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./WhoWeAre.css";


const WhoWeAre = () => {
    return (
        <>
            <section className="WhoWeAre">
                <Container fluid>
                    <div className="">
                        {/* <Container> */}
                            <Row>
                                <Col xxl={5} xl={5} lg={5} md={12}>
                                    <div className="Who-We-Are">
                                        <img
                                            className="Banner-who"
                                            src={"/assest/image/About/intro.svg"}
                                            alt=""
                                        />
                                    </div>
                                </Col>
                                <Col xxl={7} xl={7} lg={7} md={12}>
                                    <div className="who-Content">
                                        <div className="main">
                                            <p className="text">Company Overview</p>
                                        </div>
                                        <p className="content">
                                            <p className="subHead">Introduction to MeetX</p>
                                            MeetX redefines how people connect, interact, and socialize. It transforms the way we engage in today's world. Experience a new era of professional networking.
                                        </p>
                                        <p className="content">
                                            <p className="subHead">Unique Ecosystem</p>
                                            MeetX offers advanced tools, uniting individuals through, skills and interests. Real-time activity mapping displays statuses and locations for enhanced engagement.
                                        </p>
                                        <p className="content">
                                            <p className="subHead">Purpose</p>
                                            MeetX fosters collaborations and meaningful interactions. It enables users to leverage talents, expand professional circles, and drive growth.
                                        </p>
                                        <p className="content">
                                            <p className="subHead">Benefits</p>
                                            MeetX enhances collaboration and networking while supporting career and personal growth. It helps convert skills into income and fosters community.
                                        </p>
                                        <div className="border-content"></div>
                                    </div>
                                </Col>
                            </Row>
                        {/* </Container> */}
                    </div>
                </Container>
            </section>
            <div className="main">
                <p className="head">How MeetX Works</p>
                <div className="meetxWorks">
                    <div className="svg">
                        <img src="/assest/vector.svg" alt="image"/>
                    </div>
                    <div className="content">
                        <p className="subhead">Professional Profile Creation</p>
                        <ul>
                            <li>
                            Highlight your interests and skills from photography to hiking.
                            </li>
                            <li>
                            Our advanced technology connects you with local enthusiasts.
                            </li>
                        </ul>
                        <p className="subhead">Connect with Like-Minded Individuals</p>
                        <ul>
                            <li>
                            Engage with people who share your passions to form connections.
                            </li>
                            <li>
                            Perfect for collaboration or simply sharing hobbies.                            
                            </li>
                        </ul>
                        <p className="subhead">Expand Your Community</p>
                        <ul>
                            <li>
                            Effortlessly find and engage with kindred spirits.
                            </li>
                            <li>
                            MeetX makes it easy to connect with others who understand you.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <Container fluid>
                    <div className="OurVision">
                        {/* <Container> */}
                            <Row>
                                <Col xxl={7} xl={7} lg={7} md={12}>
                                    <div className="Our-Content">
                                        <div className="main">
                                            <p className="text">Company Overview</p>
                                        </div>
                                        <p className="content">
                                            <p className="subHead">Connect and Grow</p>
                                            MeetX connects professionals by skills and interests, fostering a community where users can share knowledge and grow.
                                            </p>
                                        <p className="content">
                                            <p className="subHead">Trusted Community</p>
                                            MeetX is a trusted, respectful community where everyone can contribute. We maintain a safe and inclusive environment.
                                            </p>
                                        <p className="content">
                                            <p className="subHead">Empowerment</p>
                                            MeetX provides opportunities for skill development and career advancement, helping users achieve their full potential.
                                            </p>
                                        <p className="content">
                                            <p className="subHead">Belonging</p>
                                            MeetX is an inclusive network where individuals support each other, celebrate together and create a sense of belonging.
                                            </p>
                                        <div className="border-content"></div>
                                    </div>
                                </Col>
                                <Col xxl={5} xl={5} lg={5} md={12}>
                                    <div className="Who-We-Are">
                                        <img
                                            className="banner-vision"
                                            src={"/assest/image/About/OurVision.svg"}
                                            alt=""
                                        />
                                    </div>
                                </Col>
                            </Row>
                    </div>
                </Container>
                <div className="our-mission">
                    <div className="head">
                        Our Mission
                    </div>
                    <div className="content">
                        MeetX is committed to fostering authentic human connections in an increasingly digital world. We believe in the power of real interactions to inspire growth, learning, and community. By facilitating meaningful encounters, both online and offline, we aim to enrich lives and create a more connected society.
                    </div>
                </div>
                <div className="features">
                    <div className="head">
                        Features
                    </div>
                    <div className="content">
                        <div className="circle1">
                            <img src="/assest/image/About/network.svg" alt="image"/>
                            <div className="head">
                                Networking
                            </div>
                            <div className="content">
                            Connect with professionals for career growth opportunities.
                            </div>
                        </div>
                        <div className="circle2">
                            <img src="/assest/image/About/money.svg" alt="image"/>
                            <div className="head">
                            Skill Monetization
                            </div>
                            <div className="content">
                            Capitalize on your skills by forging real, meaningful connections.
                            </div>
                        </div>
                        <div className="circle3">
                        <img src="/assest/image/About/expand.svg" alt="image"/>
                            <div className="head">
                            Expand Horizons
                            </div>
                            <div className="content">
                            Discover new activities through people who share your passions.
                            </div>
                        </div>
                    </div>
                </div>

        </>
    );
};

export default WhoWeAre;
