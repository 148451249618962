import React from 'react'
import AboutBanner from './AboutBanner/AboutBanner'
import WhoWeAre from './WhoWeAre/WhoWeAre'
import AboutTimer from './AboutTimer/AboutTimer'

const About = () => {
  return (
    <>
      <AboutBanner/>
      <WhoWeAre/>
      {/* <AboutTimer/> */}
    </>
  )
}

export default About
