import React from "react";
import "./App.css";
import "./index.css";
import { Route, Routes } from "react-router-dom";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import Home from "./home/Home";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import ContactUs from "./contact-us/ContactUs";
import MeetFounders from "./MeetFounders/MeetFounders";
import About from "./About/About";
import ScrollToTop from "./ScrollTotop/ScrollTotop";
import AppContext from "./utils/context";
import TermsAndCondition from "./AllPolicyPages/TermsAndCondition";
import CancelationRefundPolicy from "./AllPolicyPages/CancelationRefundPolicy";
import ShippingPolicy from "./AllPolicyPages/ShippingPolicy";

function App() {
  return (
    <>
      <AppContext>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/meet-founder" element={<MeetFounders />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/terms-condition" element={<TermsAndCondition/>} />
          <Route path="/cancelation-policy" element={<CancelationRefundPolicy/>} />
          <Route path="/shipping-policy" element={<ShippingPolicy/>} />
        </Routes>
        <Footer />
      </AppContext>
    </>
  );
}

export default App;
