import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const addWishlist = async (id) => {
  try {
    return await postData(`/wishlist/add`,{
      product_id:id
  });
  } catch (error) {
    console.log(error);
  }
};

export const getWishlist = async () => {
  try {
    return await getData(`/wishlist/show`);
  } catch (error) {
    console.log(error);
  }
};




