import React, { useContext, useState } from "react";
import "./ContactUs.css";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import CommanButton from "../CommanComponent/Comman_button/CommanButton";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { Context } from "../utils/context";
import { useForm } from "react-hook-form";

import classNames from "classnames";
import { postContactUs } from "../utils/apis/common/Common";
import Successfull_Modal from "../CommanComponent/Successfull_Modal/Successfull_Modal";
const ContactUs = () => {
    const [modalShow, setModalShow] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [showBg, setShowBg] = useState(false);
    const [showContactTextHolder, setShowContactTextHolder] = useState(true);

    const handleMapTextClick = () => {
        setShowForm(true);
        setShowBg(true);
        setShowContactTextHolder(false);
    };

    const handleArrowClick = () => {
        setShowContactTextHolder(true);
        setShowForm(false);
        setShowBg(false);

    };

    const { getData, IMG_URL, Select2Data } = useContext(Context);
    const { control, register, handleSubmit, getValues, setError, clearErrors, formState: { errors }, reset, watch, trigger, setValue } = useForm();



    const onSubmit = async (data) => {
        const res = await postContactUs(data);
        if (res?.success) {
            setModalShow(true); // Show the modal after successful submission
            setTimeout(() => {
                setModalShow(false); // Hide the modal after 1 second
                window.location.href = "/contact-us";
            }, 2000);
        } else {
            console.error('Error fetching data:', res?.error);
        }
    };
    return (
        <>
            <section className="contact-us">
                <div className="map-section">
                    <div className="mapouter">
                        <div className="gmap_canvas">
                            <iframe
                                className="gmap_iframe"
                                frameborder="0"
                                scrolling="no"
                                marginheight="0"
                                marginwidth="0"
                                src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=pune&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                            ></iframe>
                            <a href="https://pdflist.com/" alt="pdflist.com"></a>
                        </div>
                    </div>
                </div>
                {/* <div className="contact_form_holder "> */}
                <div className={`contact_form_holder ${showBg ? "contact_form_bg" : ""}`}>
                    <div className="container">
                        <div className="row justify-content-end">
                            <div className="col-lg-4 col-md-4">
                                <div className={`contact-text-holder ${showContactTextHolder ? "" : "d-none"}`}>
                                    <div className="heading-holder mb-4">
                                        <h2>Contact us</h2>
                                    </div>
                                    <div className="d-flex mb-3">
                                        <div className="icon-holder me-3">
                                            <img
                                                src={process.env.PUBLIC_URL + "/assest/image/icon/calling-contact.png"}
                                                className="add-icon"
                                            />
                                        </div>
                                        <div className="address-holder">
                                            <p>+91 70226 65938</p>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-3">
                                        <div className="icon-holder me-3">
                                            <img
                                                src={process.env.PUBLIC_URL + "/assest/image/icon/email-contact.png"}
                                                className="add-icon"
                                            />
                                        </div>
                                        <div className="address-holder">
                                            <p>manimonu@meetx.co.in</p>
                                        </div>
                                    </div>

                                    <div className="d-flex">
                                        <div className="icon-holder me-3">
                                            <img
                                                src={process.env.PUBLIC_URL + "/assest/image/icon/location-contact.png"}
                                                className="add-icon"
                                            />
                                        </div>
                                        <div className="address-holder">
                                            <p>
                                                Kaverappa Layout, Kadubeesanahalli, Kadabeesanahalli, Bengaluru,
                                                Karnataka 560103
                                            </p>
                                        </div>
                                    </div>

                                    <div className="d-flex">
                                        <div className="icon-holder me-3">
                                            <img
                                                src={process.env.PUBLIC_URL + "/assest/image/icon/add-contact.png"}
                                                className="add-icon"
                                            />
                                        </div>
                                        <div className="address-holder map-text" onClick={handleMapTextClick}>
                                            <p>Find Us on Map</p>
                                        </div>
                                    </div>

                                    <div className="border-holder  my-4"></div>
                                    <div className="social-icon">
                                        <img src={process.env.PUBLIC_URL + "/assest/image/icon/mobile-icon.png"} className="add-icon me-3" />

                                        <Link to="https://www.facebook.com/people/MeetX/61559032034896/?mibextid=JRoKGi" target="___blank">
                                            <img
                                                src={process.env.PUBLIC_URL + "/assest/image/icon/fcebook1.png"}
                                                className="facebook-icon me-2"
                                            />
                                        </Link>
                                        <img
                                            src={process.env.PUBLIC_URL + "/assest/image/icon/linkdin1.png"}
                                            className="facebook-icon me-2"
                                        />
                                        <img
                                            src={process.env.PUBLIC_URL + "/assest/image/icon/twitter1.png"}
                                            className="facebook-icon me-2"
                                        />
                                        <Link to="https://www.youtube.com/channel/UCeA0l5QqQZ5RNMROWkTqmIA" target="___blank">
                                            <img
                                                src={process.env.PUBLIC_URL + "/assest/image/icon/youtube1.png"}
                                                className="facebook-icon me-2"
                                            />
                                        </Link>
                                        <Link to="https://www.instagram.com/meetx_24/" target="___blank" ><FontAwesomeIcon icon={faInstagram} className="insta-icon" /></Link>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`${showForm
                                    ? "col-xl-5 col-lg-7 col-12 scale-down-hor-right"
                                    : "col-lg-8 col-md-8 scale-up-hor-right"
                                    }`}
                            >
                                <div className="form-holder ">
                                    <div
                                        className={`arrow-holder ${showForm ? "arrow-holder" : "d-none"}`}
                                        onClick={handleArrowClick}
                                    >
                                        {/* <FontAwesomeIcon className="left-arrow" icon="fa-solid fa-arrow-left" /> */}
                                        <img
                                            src={process.env.PUBLIC_URL + "/assest/image/icon/arrow-left.png"}
                                            className="left-arrow"
                                        />
                                    </div>


                                    <Form>
                                        <Row className="form_container">
                                            <Form.Group
                                                className="mb-3"
                                                controlId="exampleForm.ControlInput1"
                                            >

                                                <Form.Control
                                                    type="text"
                                                    name="fname"

                                                    placeholder="Enter Name"
                                                    {...register("name", {
                                                        required: "Name is required",
                                                    })}
                                                    className={classNames("", {
                                                        "is-invalid": errors?.name,
                                                    })}
                                                    onKeyDown={(event) => {
                                                        if (!/[A-Z-a-z ]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                />

                                            </Form.Group>

                                            <Form.Group
                                                className="mb-3"
                                                controlId="exampleForm.ControlInput1 ">
                                                <Form.Control
                                                    type="text"
                                                    name="email"

                                                    placeholder="E-mail Address"
                                                    {...register("email", {
                                                        required: "Email Id required",
                                                        pattern: {
                                                            value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
                                                            message: "Email Id Invalid",
                                                        },
                                                    })}
                                                    className={classNames("", {
                                                        "is-invalid": errors?.email,
                                                    })}
                                                />
                                            </Form.Group>

                                            <Form.Group
                                                className="mb-3"
                                                controlId="exampleForm.ControlInput1"
                                            >
                                                <Form.Control
                                                    type="text"
                                                    name="contact_number"
                                                    placeholder="contact_number"

                                                    {...register("contact_number", {
                                                        required: "subject is required",
                                                    })}
                                                    className={classNames("", {
                                                        "is-invalid": errors?.contact_number,
                                                    })}
                                                />
                                            </Form.Group>

                                            <Form.Group
                                                className="mb-3"
                                                controlId="exampleForm.ControlTextarea1"
                                            >
                                                <Form.Control
                                                    as="textarea"
                                                    name="message"
                                                    type="text"
                                                    rows={3}

                                                    placeholder="Enter Message"
                                                    {...register("message", {
                                                        required: "Message required",
                                                    })}
                                                    className={classNames("", {
                                                        "is-invalid": errors?.message,
                                                    })}
                                                />
                                            </Form.Group>
                                            <div className="btn-holder mt-4">
                                                <CommanButton
                                                    className="SubmitBtn"
                                                    text="Send Message"
                                                    padding="7px 20px"
                                                    onClick={handleSubmit(onSubmit)}
                                                />
                                            </div>
                                        </Row>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Successfull_Modal
                message="send successfully"
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    );
};

export default ContactUs;
