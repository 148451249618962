import React from 'react'
import { Container, Row } from 'react-bootstrap'
import './TermsAndCondition.css'
const TermsAndCondition = () => {
    return (
        <>
            <section className='terms-condition'>
                <Container>
                    <Row>
                        <div className='heading-holder text-center mb-3'>
                            <h1>Terms and Conditions</h1>
                        </div>
                        <div className='text-holder'>
                            <ul>
                                <li>User Eligibility: Users must be at least 18 years of age to use the
                                    Altrodav Technologies Private Limited professional social media app
                                    (hereinafter referred to as &quot;the App&quot;).
                                </li>
                                <li>User Verification: To verify their identity, users must upload a 15-
                                    second video during the registration process. Accounts will not be
                                    verified until this step is completed successfully. If verification fails,
                                    users must wait three months before attempting to reverify.</li>
                                <li>User Conduct: After verification and during the use of the App, users
                                    must maintain a positive review rating of 75% or higher. This means
                                    that users must not receive more than one report of abuse during
                                    meetings with four people. If a user is reported, they will have one
                                    chance to rectify their behavior after three months. If the user is
                                    reported again after this grace period, they will be permanently
                                    banned from the App.</li>
                                <li>Incident Review: In the event of a reported incident, the Altrodav
                                    Technologies Private Limited team will speak with both the
                                    reporting and reported parties to determine the appropriate course
                                    of action.</li>
                                <li>Intellectual Property: All intellectual property rights related to the
                                    App, including but not limited to copyrights, trademarks, and trade
                                    secrets, are owned by Altrodav Technologies Private Limited.</li>
                                <li>Limitation of Liability: Altrodav Technologies Private Limited shall
                                    not be liable for any indirect, incidental, special, or consequential
                                    damages arising out of or in connection with the use of the App.</li>
                                <li>Indemnification: Users agree to indemnify and hold Altrodav
                                    Technologies Private Limited harmless from any claims, damages, or
                                    expenses arising out of their use of the App or violation of these
                                    Terms and Conditions.</li>
                                <li>Modifications: Altrodav Technologies Private Limited reserves the
                                    right to modify these Terms and Conditions at any time without prior
                                    notice.</li>
                            </ul>
                        </div>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default TermsAndCondition
